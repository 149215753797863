import axios from "axios";
import {useStore} from "vuex";

export function downloadAchatMac() {
    // const link = document.createElement('a');
    // link.href = 'http://13.214.43.43:9000/default/ChatBase_M2_V1.2.0.zip'; // 指向你的APP下载链接
    // // link.download = 'app.apk'; // 设置下载文件的名称
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // axios.get('http://47.121.201.42:9701/v3/version/getLastVersionByAppId?appId=5') 测试环境请求接口
    axios.get('http://13.229.148.148:9701/v3/version/getLastVersionByAppId?appId=5')
        .then(response => {
            if(response.data.code===0){
                const link = response.data.data.fileUrl;
                window.open(link);
            }
            console.log('response data:', response.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}

export function downloadAchatWind() {
    // const link = document.createElement('a');
    // link.href = 'http://13.214.43.43:9000/default/ChatBase_V1.2.0.exe'; // 指向你的APP下载链接
    // // link.download = 'app.apk'; // 设置下载文件的名称
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);


    // axios.get('http://47.121.201.42:9701/v3/version/getLastVersionByAppId?appId=4')
    axios.get('http://13.229.148.148:9701/v3/version/getLastVersionByAppId?appId=4')
        .then(response => {
            if(response.data.code===0){
                const link = response.data.data.fileUrl;
                window.open(link);
            }
            console.log('response data:', response.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });

}

export function downloadAchatAndro() {
    // const link = document.createElement('a');
    // link.href = 'http://13.214.43.43:9000/default/ChatBase_V1.2.0.apk'; // 指向你的APP下载链接
    // // link.download = 'app.apk'; // 设置下载文件的名称
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // axios.get('http://47.121.201.42:9701/v3/version/getLastVersionByAppId?appId=3')
    axios.get('http://13.229.148.148:9701/v3/version/getLastVersionByAppId?appId=3')
        .then(response => {
            if(response.data.code===0){
                const link = response.data.data.fileUrl;
                window.open(link);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}

const store = useStore();
export function downloadAchatIos() {
    const link = 'https://apps.apple.com/hk/app/chat-base-pro/id6737255161';
    window.open(link, '_blank');
    // axios.get('http://47.121.201.42:9701/v3/version/getLastVersionByAppId?appId=2')
    //     .then(response => {
    //         if(response.data.code===0){
    //             store.commit('setImgUrl',response.data.fileUrl) // 指向你的APP下载链接
    //         }
    //         console.log('response data:', response.data);
    //     })
    //     .catch(error => {
    //         console.error('Error fetching data:', error);
    //     });
}

export function clickMenu(){
    const link = 'http://8.213.205.71/';
    window.open(link, '_blank');
}
